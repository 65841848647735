<div class="clock-face" [style]="faceStyle">
  <ng-content></ng-content>
  <svg
    class="cut-out"
    width="384"
    height="384"
    viewBox="0 0 384 384"
  >
    <g>
      <path
        class="face"
        d="M 0,0 V 384 H 384 V 0 Z M 192,15 A 177,177 0 0 1 369,192 177,177 0 0 1 192,369 177,177 0 0 1 15,192 177,177 0 0 1 192,15 Z"
      />
    </g>
  </svg>

  <svg
    class="demo-dial"
    width="384"
    height="384"
    viewBox="0 0 384 384"
  >
    <defs>
      <pattern id="dial-gradient" patternUnits="userSpaceOnUse" width="384" height="384">
        <image class="dial-gradient-img" href="/resources/clock/gradient.png" x="0" y="0" width="384" height="384" [style.transform]="'rotate(' + (minutes * 6) + 'deg)'" />
      </pattern>
    </defs>

    <path *ngFor="let angle of minorTicks" class="tick minor" d="M 192,27 v 10" [style.transform]="'rotate(' + angle + 'deg)'"/>
    <path *ngFor="let angle of majorTicks" class="tick major" d="M 192,27 v 18" [style.transform]="'rotate(' + angle + 'deg)'"/>

    <ng-container *ngFor="let segment of segments; trackBy: trackBySegment">
      <path class="block-segment" [attr.d]="segment.path" />
      <!-- <circle class="segment-mark start" [attr.cx]="segment.start.x" [attr.cy]="segment.start.y" r="2" style="fill:green;stroke:white;stroke-width:1px;" />
      <circle class="segment-mark end" [attr.cx]="segment.end.x" [attr.cy]="segment.end.y" r="2" style="fill:red;stroke:white;stroke-width:1px;" /> -->
    </ng-container>
    <!-- <polyline points="468.750,82.031 468.750,35 " id="polyline322" style="fill:none;stroke:#ffffff;stroke-width:4.84839;stroke-dasharray:none;stroke-opacity:1" transform="matrix(0.41250847,0,0,0.93092534,-1.3627708,-32.692008)" /> -->
    <path class="tick very major" d="M 192,0 v 45" />
    <path id="hour" class="gnomon hour" d="M 178,3 206,3 192,40 Z" [style.transform]="'rotate(' + (hours * 30) + 'deg)'" />
    <path id="minute" class="gnomon minute" d="M 180,4 204,4 192,38 Z" [style.transform]="'rotate(' + (minutes * 6) + 'deg)'" />
  </svg>
</div>