<app-indexing-progress *ngIf="showLoadingIndicator"></app-indexing-progress>

<ng-container *ngIf="specialEvent">
  <div class="pyro">
    <div class="inner a"></div>
    <div class="inner b"></div>
    <div class="inner c"></div>
  </div>
  <div class="warning-label">{{ eventName }}</div>
</ng-container>

<div *ngIf="countdown > 0" class="warning-label">{{ eventName }} in {{ countdown | number }} block{{ countdown === 1 ? '' : 's' }}!</div>

<div class="blockchain-wrapper" [class.time-ltr]="timeLtr" [class.time-rtl]="!timeLtr" #blockchainWrapper>
  <div id="blockchain-container" [dir]="timeLtr ? 'rtl' : 'ltr'" #blockchainContainer
    [class.with-menu]="hasMenu"
    (mousedown)="onMouseDown($event)"
    (pointerdown)="onPointerDown($event)"
    (touchmove)="onTouchMove($event)"
    (dragstart)="onDragStart($event)"
    (scroll)="onScroll($event)"
  >
    <app-blockchain
      [containerWidth]="chainWidth"
      [pageIndex]="pageIndex"
      [pages]="pages"
      [blocksPerPage]="blocksPerPage"
      [minScrollWidth]="minScrollWidth"
      [scrollableMempool]="true"
      (mempoolOffsetChange)="onMempoolOffsetChange($event)"
    ></app-blockchain>
  </div>
  <div class="reset-scroll" [class.hidden]="pageIndex === 0" (click)="resetScroll()">
    <fa-icon [icon]="['fas', 'circle-left']" [fixedWidth]="true"></fa-icon>
  </div>
</div>

<ng-container *serverOnly>
  <!-- disgusting hack to apply an initial scroll to server-side rendered blockchain bar -->
  <img *ngIf="!stateService.isLiquid()" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="pixel" style="visibility: hidden; position: absolute;" onload="(() => { const b = document.getElementById('blockchain-container'); const d = document.getElementById('divider'); if (b && d) { b.scrollLeft = d.getBoundingClientRect().x - (window.innerWidth * (window.innerWidth >= 768 ? 0.5 : 0.95)); }})()">
  <img *ngIf="stateService.isLiquid()" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="pixel" style="visibility: hidden; position: absolute;" onload="(() => { const b = document.getElementById('blockchain-container'); const d = document.getElementById('divider'); if (b && d) { b.scrollLeft = d.getBoundingClientRect().x - (window.innerWidth >= 768 ? 420 : (window.innerWidth * 0.5)); }})()">
</ng-container>

<router-outlet></router-outlet>
