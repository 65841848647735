<div
  #tooltip
  class="block-overview-tooltip"
  [class.clickable]="clickable"
  [style.visibility]="tx ? 'visible' : 'hidden'"
  [style.left]="getTooltipLeftPosition()"
  [style.top]="tooltipPosition.y + 'px'"
>
  <table class="table-fixed">
    <tbody>
      <tr>
        <td class="label" i18n="shared.transaction">Transaction</td>
        <td class="value">
          <a [routerLink]="['/tx/' | relativeUrl, txid]">{{ txid | shortenString : 16}}</a>
        </td>
      </tr>
      <tr *ngIf="time">
        <ng-container [ngSwitch]="timeMode">
          <ng-container *ngSwitchCase="'mempool'">
            <td class="label" i18n="transaction.first-seen|Transaction first seen">First seen</td>
            <td class="value"><i><app-time kind="since" [time]="time" [fastRender]="true"></app-time></i></td>
          </ng-container>
          <ng-container *ngSwitchCase="'missed'">
            <td class="label" i18n="transaction.first-seen|Transaction first seen">First seen</td>
            <td class="value"><i><app-time kind="before" [time]="relativeTime - time"></app-time></i></td>
          </ng-container>
          <ng-container *ngSwitchCase="'after'">
            <td class="label" i18n="transaction.first-seen|Transaction first seen">First seen</td>
            <td class="value"><i><app-time kind="span" [time]="time - relativeTime"></app-time></i></td>
          </ng-container>
          <ng-container *ngSwitchCase="'mined'">
            <td class="label" i18n="transaction.confirmed|Transaction confirmed state">Confirmed</td>
            <td class="value"><i><app-time kind="span" [time]="relativeTime - time"></app-time></i></td>
          </ng-container>
        </ng-container>
      </tr>
      <tr>
        <td class="label" i18n="dashboard.latest-transactions.amount">Amount</td>
        <td class="value"><app-amount [blockConversion]="blockConversion" [satoshis]="value" [noFiat]="true"></app-amount></td>
      </tr>
      <tr>
        <td class="label" i18n="transaction.fee|Transaction fee">Fee</td>
        <td class="value">{{ fee | number }} <span class="symbol" i18n="shared.sat|sat">sat</span> &nbsp; <span class="fiat"><app-fiat [blockConversion]="blockConversion" [value]="fee"></app-fiat></span>
      </tr>
      <tr>
        <td class="label" i18n="transaction.fee-rate|Transaction fee rate">Fee rate</td>
        <td class="value">
          <app-fee-rate [fee]="feeRate"></app-fee-rate>
        </td>
      </tr>
      <tr *ngIf="hasEffectiveRate && effectiveRate != null">
        <td *ngIf="!this.acceleration" class="label" i18n="transaction.effective-fee-rate|Effective transaction fee rate">Effective fee rate</td>
        <td *ngIf="this.acceleration" class="label" i18n="transaction.accelerated-fee-rate|Accelerated transaction fee rate">Accelerated fee rate</td>
        <td class="value" [class.oobFees]="this.acceleration">
          <app-fee-rate [fee]="effectiveRate"></app-fee-rate>
        </td>
      </tr>
      <tr *only-vsize>
        <td class="label" i18n="transaction.vsize|Transaction Virtual Size">Virtual size</td>
        <td class="value" [innerHTML]="'&lrm;' + (vsize | vbytes: 2)"></td>
      </tr>
      <tr *only-weight>
        <td class="label" i18n="transaction.weight|Transaction Weight">Weight</td>
        <td class="value" [innerHTML]="'&lrm;' + ((vsize * 4) | wuBytes: 2)"></td>
      </tr>
      <tr *ngIf="auditEnabled && tx && tx.status && tx.status.length">
        <td class="label" i18n="transaction.audit-status">Audit status</td>
        <td class="value">
          <ng-container [ngSwitch]="tx?.status">
            <span *ngSwitchCase="'found'" class="badge badge-success" i18n="transaction.audit.match">Match</span>
            <span *ngSwitchCase="'censored'" class="badge badge-danger" i18n="transaction.audit.removed|Transaction removed state">Removed</span>
            <span *ngSwitchCase="'missing'" class="badge badge-warning" i18n="transaction.audit.marginal">Marginal fee rate</span>
            <span *ngSwitchCase="'sigop'" class="badge badge-warning" i18n="transaction.audit.sigop">High sigop count</span>
            <span *ngSwitchCase="'fresh'" class="badge badge-warning" i18n="transaction.audit.recently-broadcasted">Recently broadcasted</span>
            <span *ngSwitchCase="'freshcpfp'" class="badge badge-warning" i18n="transaction.audit.recently-cpfped">Recently CPFP'd</span>
            <span *ngSwitchCase="'added'" class="badge badge-warning" i18n="tx-features.tag.added|Added">Added</span>
            <span *ngSwitchCase="'prioritized'" class="badge badge-warning" i18n="tx-features.tag.prioritized|Prioritized">Prioritized</span>
            <ng-container *ngSwitchCase="'added_prioritized'">
              <span class="badge badge-warning" i18n="tx-features.tag.added|Added">Added</span>
              <span class="badge badge-warning ml-1" i18n="tx-features.tag.prioritized|Prioritized">Prioritized</span>
            </ng-container>
            <span *ngSwitchCase="'deprioritized'" class="badge badge-warning" i18n="tx-features.tag.prioritized|Deprioritized">Deprioritized</span>
            <ng-container *ngSwitchCase="'added_deprioritized'">
              <span class="badge badge-warning" i18n="tx-features.tag.added|Added">Added</span>
              <span class="badge badge-warning ml-1" i18n="tx-features.tag.prioritized|Deprioritized">Deprioritized</span>
            </ng-container>
            <span *ngSwitchCase="'selected'" class="badge badge-warning" i18n="transaction.audit.marginal">Marginal fee rate</span>
            <span *ngSwitchCase="'rbf'" class="badge badge-warning" i18n="tx-features.tag.conflict|Conflict">Conflict</span>
            <span *ngSwitchCase="'accelerated'" class="badge badge-accelerated" i18n="transaction.audit.accelerated">Accelerated</span>
          </ng-container>
        </td>
      </tr>
      {{ activeFilters.rbf }}
      <tr *ngIf="(!auditEnabled && tx && (tx.status === 'accelerated' || tx.acc || acceleration)) || filters.length">
        <td colspan="2">
          <div class="tags mt-2" [class.any-mode]="filterMode === 'or'">
            <span *ngIf="!auditEnabled && tx && (tx.status === 'accelerated' || tx.acc || acceleration)" class="badge badge-accelerated" i18n="transaction.audit.accelerated">Accelerated</span>
            <ng-container *ngFor="let filter of filters;">
              <span class="btn badge filter-tag" [class.matching]="activeFilters[filter.key]">{{ filter.label }}</span>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
