<div class="block-filters" [class.filters-active]="activeFilters.length > 0" [class.any-mode]="filterMode === 'or'" [class.menu-open]="menuOpen" [class.small]="cssWidth < 500" [class.vsmall]="cssWidth < 400" [class.tiny]="cssWidth < 200">
  <a *ngIf="menuOpen" [routerLink]="['/docs/faq' | relativeUrl]" fragment="how-do-mempool-goggles-work" class="info-badges float-right" i18n-ngbTooltip="Mempool Goggles&trade; tooltip" ngbTooltip="select filter categories to highlight matching transactions">
    <fa-icon [icon]="['fas', 'info-circle']" [fixedWidth]="true" size="lg"></fa-icon>
  </a>
  <div class="filter-bar">
    <button class="menu-toggle" (click)="menuOpen = !menuOpen" title="Mempool Goggles&trade;">
      <app-svg-images name="goggles" width="100%" height="100%"></app-svg-images>
    </button>
    <div class="active-tags">
      <ng-container *ngFor="let filter of activeFilters;">
        <button class="btn filter-tag selected" (click)="toggleFilter(filter)">{{ filters[filter].label }}</button>
      </ng-container>
    </div>
  </div>
  <div class="filter-menu" *ngIf="menuOpen && cssWidth > 280">
    <div class="filter-row">
      <div class="filter-element">
        <h5 i18n="mempool-goggles.match">Match</h5>
        <div class="btn-group btn-group-toggle">
          <label class="btn btn-xs blue mode-toggle" [class.active]="filterMode === 'and'">
            <input type="radio" [value]="'all'" fragment="all" (click)="setFilterMode('and')"><ng-container i18n>All</ng-container>
          </label>
          <label class="btn btn-xs green mode-toggle" [class.active]="filterMode === 'or'">
            <input type="radio" [value]="'any'" fragment="any" (click)="setFilterMode('or')"><ng-container i18n="mempool-goggles.any">Any</ng-container>
          </label>
        </div>
      </div>
      <div class="filter-element">
        <h5 i18n="mempool-goggles.tint">Tint</h5>
        <div class="btn-group btn-group-toggle">
          <label class="btn btn-xs yellow mode-toggle" [class.active]="gradientMode === 'fee'">
            <input type="radio" [value]="'fee'" fragment="classic" (click)="setGradientMode('fee')"><ng-container i18n="mempool-goggles.classic">Classic</ng-container>
          </label>
          <label class="btn btn-xs blue mode-toggle" [class.active]="gradientMode === 'age'">
            <input type="radio" [value]="'age'" fragment="age" (click)="setGradientMode('age')"><ng-container i18n="mempool-goggles.age">Age</ng-container>
          </label>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let group of filterGroups;">
      <h5>{{ group.label }}</h5>
      <div class="filter-group">
        <ng-container *ngFor="let filter of group.filters;">
          <button *ngIf="!disabledFilters[filter.key]" class="btn filter-tag" [class.selected]="filterFlags[filter.key]" (click)="toggleFilter(filter.key)">{{ filter.label }}</button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="filter-menu" *ngIf="menuOpen && cssWidth <= 280">
    <ng-container *ngFor="let group of filterGroups;">
      <ng-container *ngFor="let filter of group.filters;">
        <button *ngIf="filter.important" class="btn filter-tag" [class.selected]="filterFlags[filter.key]" (click)="toggleFilter(filter.key)">{{ filter.label }}</button>
      </ng-container>
    </ng-container>
  </div>
</div>