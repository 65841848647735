<app-block-overview-graph
  #blockGraph
  [isLoading]="(isLoading$ | async) || !stateService.isBrowser"
  [resolution]="resolution"
  [blockLimit]="stateService.blockVSize"
  [orientation]="timeLtr ? 'right' : 'left'"
  [flip]="true"
  [showFilters]="showFilters"
  [filterFlags]="filterFlags"
  [filterMode]="filterMode"
  [gradientMode]="gradientMode"
  [excludeFilters]="['nonstandard']"
  [overrideColors]="overrideColors"
  (txClickEvent)="onTxClick($event)"
></app-block-overview-graph>
