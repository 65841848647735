<div class="sidenav menu-click" [class]="navOpen ? 'open': ''">
  <div class="d-flex menu-click">

    <nav class="scrollable menu-click">
      <span *ngIf="user$ | async as user" class="menu-click">
        <span class="menu-click text-nowrap ellipsis">
          <strong>
            <span *ngIf="user.username.includes('@'); else usernamenospace">{{ user.username }}</span>
            <ng-template #usernamenospace>&#64;{{ user.username }}</ng-template>
          </strong>
        </span>
        <span class="badge mr-1 badge-og" *ngIf="user.ogRank">
          OG #{{ user.ogRank }}
        </span>
        <span class="badge mr-1 badge-default" [class]="'badge-' + user.subscription_tag" *ngIf="user.subscription_tag !== 'free'">
          {{ user.subscription_tag.toUpperCase() }}
        </span>
      </span>
      <a *ngIf="!userAuth" class="d-flex justify-content-center align-items-center nav-link m-0  menu-click" routerLink="/login" role="tab" (click)="onLinkClick('/login')">
        <fa-icon class="menu-click" [icon]="['fas', 'user-circle']" [fixedWidth]="true" style="font-size: 25px;margin-right: 15px;"></fa-icon>
        <span class="menu-click" style="font-size: 20px;" i18n="shared.sign-in">Sign In</span>
      </a>

      <ng-container *ngIf="userMenuGroups$ | async as menuGroups">
        <div class="menu-click" *ngFor="let group of menuGroups" style="height: max-content;">
          @if (menuGroups.length > 1) {
            <h6 class="d-flex justify-content-between align-items-center mt-4 mb-2 text-uppercase menu-click">
              <span class="menu-click">{{ group.title }}</span>
            </h6>
          } @else {
            <span class="d-block mt-2"></span>
          }
          <ul class="nav flex-column menu-click" *ngFor="let item of group.items" (click)="onLinkClick(item.link)">
            <li class="nav-item d-flex justify-content-start align-items-center menu-click">
              <fa-icon class="menu-click" [icon]="['fas', item.faIcon]" [fixedWidth]="true"></fa-icon>
              <button *ngIf="item.link === 'logout'" class="btn nav-link menu-click" role="tab" (click)="logout()">{{ item.title }}</button>
              <a *ngIf="item.title !== 'Logout'" class="nav-link menu-click" [routerLink]="[item.link]" role="tab">
                {{ item.title }}
                @if (item.isExternal === true) {
                  <fa-icon [icon]="['fas', 'external-link-alt']" [fixedWidth]="true" style="margin-left: 5px; font-size: 13px; color: lightgray"></fa-icon>
                }
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </nav>

  </div>

</div>