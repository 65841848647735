<div *ngIf="showTitle && mode === 'difficulty'" class="main-title" i18n="dashboard.difficulty-adjustment">Difficulty Adjustment</div>
<div *ngIf="showTitle && mode === 'halving'" class="main-title" i18n="dashboard.halving-countdown">Halving Countdown</div>
<div class="card-wrapper">
  <div class="card">
    <!-- <div class="widget-toggler">
      <a href="" (click)="setMode('difficulty')" class="toggler-option"
        [ngClass]="{'inactive': mode === 'difficulty'}"><small i18n="statistics.average-small">difficulty</small></a>
      <span style="color: var(--transparent-fg); font-size: 8px"> | </span>
      <a href="" (click)="setMode('halving')" class="toggler-option"
        [ngClass]="{'inactive': mode === 'halving'}"><small i18n="statistics.median-small">halving</small></a>
    </div> -->
    <div class="card-body more-padding">
      <div class="difficulty-adjustment-container" *ngIf="(isLoadingWebSocket$ | async) === false && (difficultyEpoch$ | async) as epochData; else loadingDifficulty">
        <div class="epoch-progress">
          <svg #epochSvg class="epoch-blocks" height="22px" width="100%" viewBox="0 0 224 9" shape-rendering="crispEdges" preserveAspectRatio="none">
            <defs>
              <linearGradient id="diff-gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="var(--primary)" />
                <stop offset="100%" stop-color="var(--mainnet-alt)" />
              </linearGradient>
              <linearGradient id="diff-hover-gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="#2486eb" />
                <stop offset="100%" stop-color="#ae6af7" />
              </linearGradient>
            </defs>
            <rect
              *ngFor="let rect of shapes"
              [attr.x]="rect.x" [attr.y]="rect.y"
              [attr.width]="rect.w" [attr.height]="rect.h"
              class="rect {{rect.status}}"
              [class.hover]="hoverSection && rect.status === hoverSection.status"
              (pointerover)="onHover($event, rect);"
              (pointerout)="onBlur();"
            >
              <animate
                *ngIf="rect.status === 'next'"
                attributeType="XML"
                attributeName="fill"
                [attr.values]="'#fff;' + (rect.expected ? '#D81B60' : '#2d3348') + ';#fff'"
                dur="2s"
                repeatCount="indefinite"/>
            </rect>
          </svg>
        </div>
        <div class="difficulty-stats">
          <div class="item">
            <div class="card-text">
              ~<app-time [time]="epochData.timeAvg / 1000" [fractionDigits]="1"></app-time>
            </div>
            <div class="symbol" i18n="difficulty-box.average-block-time">Average block time</div>
          </div>
          <div class="item">
            <div class="card-text bigger" [ngStyle]="{'color': epochData.colorAdjustments}">
              <span *ngIf="epochData.change > 0; else arrowDownDifficulty" >
                <fa-icon class="retarget-sign" [icon]="['fas', 'caret-up']" [fixedWidth]="true"></fa-icon>
              </span>
              <ng-template #arrowDownDifficulty >
                <fa-icon class="retarget-sign" [icon]="['fas', 'caret-down']" [fixedWidth]="true"></fa-icon>
              </ng-template>
              {{ epochData.change | absolute | number: '1.2-2' }}
              <span class="symbol">%</span>
            </div>
            <div class="symbol">
              <span i18n="difficulty-box.previous">Previous</span>:
              <span [ngStyle]="{'color': epochData.colorPreviousAdjustments}">
                <span *ngIf="epochData.previousRetarget > 0; else arrowDownPreviousDifficulty" >
                  <fa-icon class="previous-retarget-sign" [icon]="['fas', 'caret-up']" [fixedWidth]="true"></fa-icon>
                </span>
                <ng-template #arrowDownPreviousDifficulty >
                  <fa-icon class="previous-retarget-sign" [icon]="['fas', 'caret-down']" [fixedWidth]="true"></fa-icon>
                </ng-template>
                {{ epochData.previousRetarget | absolute | number: '1.2-2' }} </span> %
            </div>
          </div>
          <div class="item">
            <div class="card-text"><app-time kind="until" [time]="epochData.estimatedRetargetDate" [fastRender]="true" [precision]="1"></app-time></div>
            <div class="symbol">
              {{ epochData.retargetDateString }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 
<ng-template #halving>
  <div class="card-body more-padding">
    <div class="difficulty-adjustment-container halving" *ngIf="(isLoadingWebSocket$ | async) === false && (difficultyEpoch$ | async) as epochData; else loadingDifficulty">
      <div class="halving-progress">
        <div class="background"></div>
        <div class="remaining" [style]="{ left: ((210000 - epochData.blocksUntilHalving) / 2100).toFixed(2) + '%' }"></div>
        <div class="label">
          {{ ((210000 - epochData.blocksUntilHalving) / 2100).toFixed(2) }}%
        </div>
      </div>
      <div class="difficulty-stats">
        <div class="item">
          <div class="card-text bigger">
            <app-btc [satoshis]="nextSubsidy"></app-btc>
          </div>
          <div class="symbol">
            <span i18n="difficulty-box.new-subsidy">New subsidy</span>
          </div>
        </div>
        <div class="item">
          <div class="card-text">
            {{ epochData.blocksUntilHalving | number }}
          </div>
          <div class="symbol">
            <span *ngIf="epochData.blocksUntilHalving > 1" i18n="shared.blocks-remaining">Blocks remaining</span>
            <span *ngIf="epochData.blocksUntilHalving === 1" i18n="shared.block-remaining">Block remaining</span>
          </div>
        </div>
        <div class="item">
          <div class="card-text" i18n-ngbTooltip="mining.average-fee" placement="bottom">
            <span>{{ epochData.timeUntilHalving | date }}</span>
          </div>
          <div class="symbol" *ngIf="epochData.blocksUntilHalving === 1; else approxTime">
            <app-time kind="until" [time]="epochData.adjustedTimeAvg + now" [fastRender]="false" [fixedRender]="true" [precision]="1" minUnit="minute"></app-time>
          </div>
          <ng-template #approxTime>
            <div class="symbol">
              <app-time kind="until" [time]="epochData.timeUntilHalving" [fastRender]="false" [fixedRender]="true" [precision]="0" [numUnits]="2" [units]="['year', 'day', 'hour', 'minute']"></app-time>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #loadingDifficulty>
  <div class="epoch-progress">
    <div class="skeleton-loader"></div>
  </div>
  <div class="difficulty-skeleton loading-container">
    <div class="item">
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
  </div>
</ng-template>

<app-difficulty-tooltip
  *ngIf="hoverSection && (isLoadingWebSocket$ | async) === false && (difficultyEpoch$ | async) as epochData"
  [cursorPosition]="tooltipPosition"
  [status]="hoverSection.status"
  [progress]="epochData"
></app-difficulty-tooltip>