<div class="container-xl">

  <div class="title-block justify-content-center">
    <h1 i18n="testnet4.faucet">Testnet4 Faucet</h1>
  </div>

  <div class="faucet-container text-center">
  
    @if (txid) {
      <div class="alert alert-success w-100 text-truncate">
        <fa-icon [icon]="['fas', 'circle-check']"></fa-icon>
        Sent!
        <a class="text-primary" [href]="'/testnet4/tx/' + txid">{{ txid }}</a>
      </div>
    }
    @if (loading) {
      <p>Loading faucet...</p>
      <div class="spinner-border text-light"></div>
    } @else if (!user) {
      <!-- User not logged in -->
      <div class="alert alert-mempool d-block text-center w-100">
        <div class="d-inline align-middle">
          <span>To use the faucet, please&nbsp;</span>
          <a routerLink="/login" [queryParams]="{'redirectTo': '/testnet4/faucet'}">login</a>
          <span class="mr-2">&nbsp;or</span>
        </div>
        <app-twitter-login customClass="btn btn-sm" width="220px" redirectTo="/testnet4/faucet" buttonString="Sign up with Twitter"></app-twitter-login>
      </div>
    }
    @else if (error === 'not_available') {
      <!-- User logged in but not a paid user or did not link its Twitter account -->
      <div class="alert alert-mempool d-block text-center w-100">
        <div class="d-inline align-middle">
          <span class="mb-2 mr-2">To use the faucet, please</span>
        </div>
        <app-twitter-login customClass="btn btn-sm" width="180px" redirectTo="/testnet4/faucet" buttonString="Link your Twitter"></app-twitter-login>
      </div>
    }
    @else if (error) {
      <!-- User can request -->
      <app-mempool-error class="w-100" [error]="error"></app-mempool-error>
    }

    @if (!loading) {
      <form [formGroup]="faucetForm" class="formGroup" (submit)="requestCoins()">
        <div class="row">
          <div class="col">
            <div class="form-group mb-0">
              <div class="input-group input-group-lg">
                <div class="input-group-prepend">
                  <span class="input-group-text" i18n="amount-sats">Amount (sats)</span>
                </div>
                <input type="number" class="form-control" [class]="{invalid: invalidAmount}" formControlName="satoshis" id="satoshis">
                <div class="button-group">
                  <button type="button" class="btn btn-secondary" (click)="setAmount(5000)">5k</button>
                  <button type="button" class="btn btn-secondary ml-2" (click)="setAmount(50000)">50k</button>
                  <button type="button" class="btn btn-secondary ml-2" (click)="setAmount(500000)">500k</button>
                </div>
              </div>
              <div class="text-danger text-left" *ngIf="invalidAmount">
                <div *ngIf="amount?.errors?.['required']">Amount is required</div>
                <div *ngIf="amount?.errors?.['min']">Minimum is {{ amount?.errors?.['min'].min | number }} tSats</div>
                <div *ngIf="amount?.errors?.['max']">Maximum is {{ amount?.errors?.['max'].max | number }} tSats</div>
              </div>
              <div class="input-group input-group-lg mt-2">
                <div class="input-group-prepend">
                  <span class="input-group-text" i18n="address">Address</span>
                </div>
                <input type="text" class="form-control" [class]="{invalid: invalidAddress}" formControlName="address" id="address" placeholder="tb1q...">
                <button type="submit" class="btn btn-primary submit-button" [disabled]="!faucetForm.valid || !faucetForm.get('address')?.dirty || isDisabled()" i18n="testnet4.request-coins">Request Testnet4 Coins</button>
              </div>
              <div class="text-danger text-left" *ngIf="invalidAddress">
                <div *ngIf="address?.errors?.['required']">Address is required</div>
                <div *ngIf="address?.errors?.['pattern']">Must be a valid testnet4 address</div>
                <div *ngIf="address?.errors?.['forbiddenAddress']">You cannot use this address</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    }

    <!-- Send back coins -->
    @if (status?.address) {  
      <div class="mt-4 alert alert-info w-100">If you no longer need your testnet4 coins, please consider <a class="text-primary" [routerLink]="['/address/' | relativeUrl, status.address]"><u>sending them back</u></a> to replenish the faucet.</div>
    }

  </div>

</div>