<div
  class="text-center" class="blockchain-wrapper" [class.time-ltr]="timeLtr" #container
  [class.ltr-transition]="ltrTransitionEnabled" [style.width]="width + 'px'" [style.height]="height + 'px'"
>
  <div class="position-container" [ngClass]="network ? network : ''" [style.top]="(height / 3) + 'px'">
    <span>
      <div class="blocks-wrapper">
        <app-mempool-blocks
          [minimal]="true"
          [count]="mempoolBlocks"
          [blockWidth]="blockWidth"
          [spotlight]="mode === 'mempool' ? index + 1 : 0"
          [getHref]="getMempoolUrl"
        ></app-mempool-blocks>
        <app-blockchain-blocks
          [minimal]="true"
          [count]="blockchainBlocks"
          [blockWidth]="blockWidth"
          [showPools]="false"
          [spotlight]="mode === 'mined' ? -index - 1 : 0"
          [getHref]="getMinedUrl"
        ></app-blockchain-blocks>
      </div>
      <div class="divider" [style.top]="-(height / 6) + 'px'">
        <svg
          viewBox="0 0 2 175"
          [style.width]="'2px'"
          [style.height]="(5 * height / 6) + 'px'"
        >
          <line
            class="divider-line"
            x0="0"
            x1="0"
            y0="0"
            y1="175px"
          ></line>
        </svg>
      </div>
    </span>
  </div>
</div>
